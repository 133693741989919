// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cenik-jsx": () => import("./../../../src/pages/cenik.jsx" /* webpackChunkName: "component---src-pages-cenik-jsx" */),
  "component---src-pages-en-contact-and-information-jsx": () => import("./../../../src/pages/en/contact-and-information.jsx" /* webpackChunkName: "component---src-pages-en-contact-and-information-jsx" */),
  "component---src-pages-en-explore-the-mine-jsx": () => import("./../../../src/pages/en/explore-the-mine.jsx" /* webpackChunkName: "component---src-pages-en-explore-the-mine-jsx" */),
  "component---src-pages-en-index-jsx": () => import("./../../../src/pages/en/index.jsx" /* webpackChunkName: "component---src-pages-en-index-jsx" */),
  "component---src-pages-en-personal-data-protection-policy-jsx": () => import("./../../../src/pages/en/personal-data-protection-policy.jsx" /* webpackChunkName: "component---src-pages-en-personal-data-protection-policy-jsx" */),
  "component---src-pages-en-price-list-jsx": () => import("./../../../src/pages/en/price-list.jsx" /* webpackChunkName: "component---src-pages-en-price-list-jsx" */),
  "component---src-pages-en-visit-the-mine-animated-tours-jsx": () => import("./../../../src/pages/en/visit-the-mine/animated-tours.jsx" /* webpackChunkName: "component---src-pages-en-visit-the-mine-animated-tours-jsx" */),
  "component---src-pages-en-visit-the-mine-culinary-jsx": () => import("./../../../src/pages/en/visit-the-mine/culinary.jsx" /* webpackChunkName: "component---src-pages-en-visit-the-mine-culinary-jsx" */),
  "component---src-pages-en-visit-the-mine-events-jsx": () => import("./../../../src/pages/en/visit-the-mine/events.jsx" /* webpackChunkName: "component---src-pages-en-visit-the-mine-events-jsx" */),
  "component---src-pages-en-visit-the-mine-shop-and-souvenirs-jsx": () => import("./../../../src/pages/en/visit-the-mine/shop-and-souvenirs.jsx" /* webpackChunkName: "component---src-pages-en-visit-the-mine-shop-and-souvenirs-jsx" */),
  "component---src-pages-en-visit-the-mine-the-export-pit-jsx": () => import("./../../../src/pages/en/visit-the-mine/the-export-pit.jsx" /* webpackChunkName: "component---src-pages-en-visit-the-mine-the-export-pit-jsx" */),
  "component---src-pages-en-visit-the-mine-the-main-pit-jsx": () => import("./../../../src/pages/en/visit-the-mine/the-main-pit.jsx" /* webpackChunkName: "component---src-pages-en-visit-the-mine-the-main-pit-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-izjava-o-dostopnosti-jsx": () => import("./../../../src/pages/izjava-o-dostopnosti.jsx" /* webpackChunkName: "component---src-pages-izjava-o-dostopnosti-jsx" */),
  "component---src-pages-kontakt-in-informacije-jsx": () => import("./../../../src/pages/kontakt-in-informacije.jsx" /* webpackChunkName: "component---src-pages-kontakt-in-informacije-jsx" */),
  "component---src-pages-obisci-rudnik-animirana-vodenja-jsx": () => import("./../../../src/pages/obisci-rudnik/animirana-vodenja.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-animirana-vodenja-jsx" */),
  "component---src-pages-obisci-rudnik-glavni-rov-jsx": () => import("./../../../src/pages/obisci-rudnik/glavni-rov.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-glavni-rov-jsx" */),
  "component---src-pages-obisci-rudnik-izvozni-rov-jsx": () => import("./../../../src/pages/obisci-rudnik/izvozni-rov.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-izvozni-rov-jsx" */),
  "component---src-pages-obisci-rudnik-kulinarika-jsx": () => import("./../../../src/pages/obisci-rudnik/kulinarika.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-kulinarika-jsx" */),
  "component---src-pages-obisci-rudnik-sitarjevski-hodi-jsx": () => import("./../../../src/pages/obisci-rudnik/sitarjevski-hodi.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-sitarjevski-hodi-jsx" */),
  "component---src-pages-obisci-rudnik-tradicionalni-dogodki-jsx": () => import("./../../../src/pages/obisci-rudnik/tradicionalni-dogodki.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-tradicionalni-dogodki-jsx" */),
  "component---src-pages-obisci-rudnik-trgovina-in-spominki-jsx": () => import("./../../../src/pages/obisci-rudnik/trgovina-in-spominki.jsx" /* webpackChunkName: "component---src-pages-obisci-rudnik-trgovina-in-spominki-jsx" */),
  "component---src-pages-politika-varstva-osebnih-podatkov-jsx": () => import("./../../../src/pages/politika-varstva-osebnih-podatkov.jsx" /* webpackChunkName: "component---src-pages-politika-varstva-osebnih-podatkov-jsx" */),
  "component---src-pages-razisci-rudnik-sitarjevec-jsx": () => import("./../../../src/pages/razisci-rudnik-sitarjevec.jsx" /* webpackChunkName: "component---src-pages-razisci-rudnik-sitarjevec-jsx" */),
  "component---src-templates-mine-product-en-js": () => import("./../../../src/templates/MineProductEn.js" /* webpackChunkName: "component---src-templates-mine-product-en-js" */),
  "component---src-templates-mine-product-js": () => import("./../../../src/templates/MineProduct.js" /* webpackChunkName: "component---src-templates-mine-product-js" */)
}

